import Me from "@/components/templates/profile/Me";
import BaseLayout from "@/components/BaseLayout";
import HttpClient from "@/utils/HttpClient";
import { useEffect, useState } from "react";

const Profile = () => {
    const [myProfile, setMyProfile]: any = useState(null)    
    useEffect(() => {
        HttpClient.get('/me')
        .then((resp) => {
            setMyProfile(resp?.data)
        })
    }, [])

    return <BaseLayout>
        <Me data={myProfile}/>
    </BaseLayout>
}

export default Profile;