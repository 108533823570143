import { Avatar, Button, Form, Input, List, message, Modal, Space, Typography } from "antd"
import SeacrhItem from "@/components/molecules/search-items/SeacrhItem";
import { EditModalInterface } from "@/interfaces/geolocation-interface";
import { MemberInterface } from "@/interfaces/user-interface";
import { DeleteOutlined } from "@ant-design/icons";
import HttpClient from "@/utils/HttpClient";
import React, { useState } from "react";
import { config } from "@/config";

const EditModal = (props: EditModalInterface) => {
    const { data } = props;
    const [userList, setUserList] = useState<MemberInterface[]>([]);
    const [dataMembers, setDataMembers]:any[] = useState(data?.members);
    const [userListLoading, setUserListLoading] = useState(false);
    
    const [form] = Form.useForm();

    const onSearchMember = (value: string) => {
        setUserListLoading(true);
        HttpClient.get('/users', {
            params: {
                username: value 
            }
        })
        .then(_res=> {
                setUserListLoading(false);
                setUserList(_res.data)
            })
            .catch(_err => {
                message.error('Error create data!');
            })
    };

    const onClickMember = (member: MemberInterface) => {
        if (dataMembers.length) {
            if (dataMembers.some((item: MemberInterface) => member.username !== item.username)) {
                const dataMember: object[] = [...dataMembers, member];
                setDataMembers(dataMember);
            }
        } else {
            const dataMember: object[] = [...dataMembers, member];
            setDataMembers(dataMember);
        }
    }

    const handleDeleteMember = (member: MemberInterface) => {
        const dataMember = dataMembers.filter((item: MemberInterface) => member.username !== item.username)
        setDataMembers(dataMember);
    }

    /**
     * FORM
     */
    const onFinish = (item:any) => {
        const payload = {
            "lat": item.lat,
            "lng": item.lng,
            "name": item.name,
            "address": item.address,
            "members": dataMembers
        }

        HttpClient.put('/geolocations/'+data?._id, payload)
            .then(_res => {
                form.resetFields()
                props.onCancel()
                message.success('Submit success!');
                form.resetFields()
            })
            .catch(_err => {
                message.error('Error update data!');
            })
    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    return <Modal
        {...props}
        footer={null}
    >
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

            <Form.Item
                name="name"
                label="Nama Lokasi"
                rules={[
                    {
                        required: true
                    }
                ]}
                initialValue={data?.name}
            >
                <Input placeholder="Masukkan Nama Lokasi" />
            </Form.Item>
            <Form.Item
                name="address"
                label="Alamat"
                rules={[
                    {
                        required: true
                    }
                ]}
                initialValue={data?.address}
            >
                <Input placeholder="Masukkan Alamat" />
            </Form.Item>
            <Form.Item
                name="lat"
                label="Latitude"
                rules={[
                    {
                        required: true
                    }
                ]}
                initialValue={data?.lat}
            >
                <Input placeholder="Masukkan Latitide" />
            </Form.Item>
            <Form.Item
                name="lng"
                label="Longitude"
                rules={[
                    {
                        required: true
                    }
                ]}
                initialValue={data?.lng}
            >
                <Input placeholder="Masukkan Longitude" />
            </Form.Item>
            
            <Typography.Title level={5} className="member-lebel" style={{color: '#d1d1d1'}}>Members</Typography.Title>
            <SeacrhItem 
                data={userList} 
                loading={userListLoading}
                onSearch={(value: string) => onSearchMember(value)}
                onClick={(item: any) => onClickMember(item)}
                placeholder="Search user by username"
            />
            {
                dataMembers.length &&
                    <div className="member-box">
                        <List
                            itemLayout="horizontal"
                            dataSource={dataMembers}
                            className="member-list"
                            renderItem={(item:any) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src={item?.avatar} />}
                                        title={<a target="_blank" rel="noopener noreferrer" href={`${config('callink_url')}/direct/${item.username}`}>{item.name}</a>}
                                        description={item?.emails[0]?.address ?? '-'}
                                    />
                                    <Button 
                                        type="primary" 
                                        ghost 
                                        danger 
                                        className="btn-call" 
                                        onClick={() => handleDeleteMember(item)}>
                                            <DeleteOutlined /> Delete
                                    </Button>
                                </List.Item>
                            )}
                        />
                    </div>
            }

            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <Button htmlType="button" style={{background: '#9a3836',borderColor: '#8c8c8c'}} onClick={() => {
                        props.onCancel()
                        form.resetFields()
                    }}>
                        Cancel
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    </Modal>
}

export default React.memo(EditModal)