import { Avatar, Input, List, Skeleton } from "antd"
import { Fragment } from "react"
import { config } from "@/config";

import "./search-item.less";


interface SearchItemInterface {
    data: object[],
    onClick: Function,
    onSearch: Function,
    loading?: boolean,
    placeholder?: string,
}

const SeacrhItem = (props: SearchItemInterface) => {

    const {
        data,
        onSearch,
        loading = false,
        placeholder="Search",
        onClick
    } = props

    return <Fragment>
        <Input placeholder={placeholder} type={'text'} onChange={(el) => onSearch(el.target.value)}/>
        <Skeleton 
            active 
            loading={loading} 
            avatar 
            paragraph={{rows: 1}}
            className="skeleton-loading"
        >
            {
                data.length &&
                    <List
                        style={{background: '#fff'}}
                        itemLayout="horizontal"
                        dataSource={data}
                        className="search-list"
                        renderItem={(item:any) => (
                            <List.Item onClick={() => onClick(item)}>
                                <List.Item.Meta
                                avatar={<Avatar src={item?.avatar} />}
                                title={<a target="_blank" rel="noopener noreferrer" href={`${config('callink_url')}/direct/${item.username}`}>{item.name}</a>}
                                description={item?.emails[0]?.address ?? '-'}
                                />
                            </List.Item>
                        )}
                    />
            }
        </Skeleton>
    </Fragment>
}

export default SeacrhItem;