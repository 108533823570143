import { GeolocationInterface } from "@/interfaces/geolocation-interface";
import CreateModal from "@/components/organisms/geolocation/CreateModal";
import GeoTables from "@/components/organisms/geolocation/GeoTables";
import { GeolocationMapper } from "@/mappers/geolocation-mapper";
import Loading from "@/components/organisms/loading/Loading";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { Content } from "antd/lib/layout/layout";
import { Button, Input, message } from "antd";
import HttpClient from "@/utils/HttpClient";

import "./style.less"
import EditModal from "@/components/organisms/geolocation/EditModal";
import _ from "lodash";


const GeolocationList = () => {
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [geolocationSelected, setGeolocationSelected] = useState<GeolocationInterface|null>(null)
    const [reFetch, setReFetch] = useState(0)
    
    const [geolocationData, setGeolocationData]:any = useState(null);
    const [geolocationParams, setGeolocationParams] = useState({
        per_page: 10,
        page: 1,
        search: ''
    })

    useEffect(() => {

        HttpClient.get('/geolocations', {
            params: geolocationParams
        })
            .then(res => {
                const data:GeolocationInterface[] = GeolocationMapper(res)
                setGeolocationData(data);
            }) 
            .catch(_err => {
                message.error('Error to load data!');      
            })
    }, [showCreateModal, geolocationParams, geolocationSelected, reFetch])

    const onChangePaginationHandler = (paginateData: any) => {
        setGeolocationParams({
            ...geolocationParams,
            per_page: paginateData.pageSize,
            page: paginateData.current
        })
    }

    return (
        <Fragment>
            <div className="container">
                <div className="table-header">
                    <Input
                        placeholder="Cari lokasi" 
                        onChange={(val) => setGeolocationParams({...geolocationParams, search: val.target.value})} 
                        style={{ width: 300, height: 35 }}
                        className="header-search"
                    />
                    <Button 
                        type="primary"
                        size="large"
                        ghost
                        onClick={() => setShowCreateModal(true)}
                    ><PlusCircleOutlined /> Tambah</Button>
                </div>
                <Content>
                    {
                        (geolocationData)
                        ? <GeoTables 
                            data={geolocationData}
                            onChangePagination={onChangePaginationHandler}
                            onGeolocationSelected={(val: GeolocationInterface) => setGeolocationSelected(val)}
                            reFetch={setReFetch}
                            />
                        : <Loading/>
                    }
                </Content>
            </div>
            <CreateModal 
                visible={showCreateModal}
                onCancel={() => setShowCreateModal(false)}
                onOk={(res:any) => console.log(res)}
            />
            {
                !_.isEmpty(geolocationSelected) &&
                    <EditModal
                        data={geolocationSelected}
                        visible={!_.isEmpty(geolocationSelected)}
                        onCancel={() => setGeolocationSelected(null)}
                        onOk={(res:any) => console.log(res)}
                    />
            }
        </Fragment>
    )
}

export default GeolocationList;