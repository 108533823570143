import { GeolocationInterface } from "@/interfaces/geolocation-interface";
import GeoMaps from "@/components/organisms/geolocation/GeoMaps";
import { GeolocationMapper } from "@/mappers/geolocation-mapper";
import Loading from "@/components/organisms/loading/Loading";
import BaseLayout from "@/components/BaseLayout";
import HttpClient from "@/utils/HttpClient";
import { message } from "antd";
import { useEffect, useState } from "react"

const Geolocation: React.FC = () => {
    const [geolocationData, setGeolocationData]:any = useState(null);

    useEffect(() => {

        HttpClient.get('/geolocations/all')
            .then(res => {
                const data:GeolocationInterface[] = GeolocationMapper(res)
                setGeolocationData(data);
            }) 
            .catch(_err => {
                message.error('Error to load data!');      
            })
    }, [])

    return <BaseLayout>
        {
            (geolocationData)
            ? <GeoMaps data={geolocationData.data}/>
            : <Loading/>
        }
    </BaseLayout>
}

export default Geolocation;