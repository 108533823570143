import Geolocation from "./pages/geolocation/Geolocation"
import NotFound from "./pages/not-found/NotFound"
import { Route, Routes } from "react-router-dom"
import Profile from "./pages/profile/Profile"
import Callback from "./pages/oauth/callback"
import Login from "@pages/login/Login"
import Auth from "@middleware/Auth"
import Home from "@pages/home/Home"

const BaseRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Auth/>}>
                <Route path="/" element={<Home/>} />
                <Route path="/me" element={<Profile/>} />
                <Route path="geolocation" element={<Geolocation/>} />
            </Route>
            <Route path="login" element={<Login/>}/>
            <Route path="oauth/callback" element={<Callback/>}/>
            <Route path="*" element={<NotFound/>} />
        </Routes>
    )
}

export default BaseRoutes