import { DownOutlined, LoginOutlined, UserOutlined } from "@ant-design/icons";
import { Header as AntHeader } from "antd/lib/layout/layout";
import { logoutHandler } from "@/utils/Helper";
import { Button, Dropdown, Menu, message } from "antd";
import HttpClient from "@/utils/HttpClient";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";

const menu = (me: any) => {
 
  return  <Menu
    items={[
      {
        label: <Link to="/me"><UserOutlined /> {me.name}</Link>,
        key: '0',
      },
      {
        label: <a href="#logout" onClick={logoutHandler}><LoginOutlined /> Logout</a>,
        key: '1',
      }
    ]}
  />
};

const Header = () => {

  const [me, setMe] = useState<any>({name: ""});
  useEffect(() => {
    HttpClient.get(`/me`)
    .then(resp => {   
        setMe(resp.data);
      })
      .catch(_err => {
          message.error("Error to get detail profile");
      });
  }, [])

    return (
        <AntHeader>
          <div className="left-box">
            <div className="logo">GEO-Communication</div>
            <div className="menus">
              <Button type="primary" ghost>
                <Link to="/">Home</Link>
              </Button>
              <Button type="primary" ghost>
                <Link to="/geolocation">Peta</Link>
              </Button>
            </div>
          </div>
          <div className="right-box">
            <div className="profiles">
              <Dropdown overlay={() => menu(me)} trigger={['click']} placement="bottomRight">
                <Button type="primary" ghost>Profile <DownOutlined /></Button>
              </Dropdown>
            </div>
          </div>
        </AntHeader>
    )
}

export default Header