import { config } from "@/config"
import { isLoginin } from "@/middleware/Auth"
import { Button, Space } from "antd"
import Styled from "./Styled"

const Login = () => {
    isLoginin && (window.location.href = `/`)

    const oauthHandler = () => {
        const url = `${config('callink_authorize_url')}?client_id=${config('callink_client_id')}&redirect_uri=${config('callink_redirect_url')}`
        window.location.href = url;
    }

    return (
        <Styled> 
            <Space size={"large"}>
                <img src="/logo.png" alt="Logo"/>
                <Button
                    type="primary" 
                    shape="round" 
                    size="large"
                    onClick={oauthHandler}
                >
                    Login with e-collab    
                </Button>
            </Space>
        </Styled>
    )
}

export default Login