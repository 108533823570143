import { config } from "@/config"
import socketInstance from "./SocketHandler";

let peerConnection: any;

const peerConfig: any = {
    iceServers: [
        // {
        //     urls: "stun:stun.l.google.com:19302",
        // },
        {
            urls: config('stun_host')
        },
        {
            urls: config('turn_host'),
            username: config('turn_username'),
            credential: config('turn_credential')
        }
    ],
    iceTransportPolicy: 'all'
}

export const createPeerConnection = (data: any): RTCPeerConnection => {
    peerConnection = new RTCPeerConnection(peerConfig);
    peerConnection.onicecandidate = (event: any) => {
        console.log("Getting ice candidare from stun server")
        if(event.candidate) {
            // Send our candidate to other peer
            socketInstance.emit("webRTC-signaling", {
                socketId  : data.socketId,
                type      : "ICE_CANDIDATE",
                candidate : event.candidate
            })
            console.log("SEND ICE CANDIDATE ", data)
        }
    }


    peerConnection.onconnectionstatechange = () => {
        if(peerConnection.connectionState === "connected") {
            console.log("successfully connected with othe peer")
        }
    }

    return peerConnection;
}


export const sendWebRTCOffer = async (data: any) => {
    try {
        const offer = await peerConnection.createOffer()
        await peerConnection.setLocalDescription(new RTCSessionDescription(offer))
        socketInstance.emit("webRTC-signaling", {
            socketId : data.socketId,
            type     : "OFFER",
            offer    : offer
        })
        console.log("WEB RTC SEND OFFER", offer)
    } catch (error) {
        console.log("Error in sending offer", error)
    }
}

export const handleWebRTCOffer = async (data: any) => {
    try {
        await peerConnection.setRemoteDescription(new RTCSessionDescription(data.offer))
        const answer = await peerConnection.createAnswer()
        await peerConnection.setLocalDescription(new RTCSessionDescription(answer))
        socketInstance.emit("webRTC-signaling", {
            socketId : data.socketId,
            type     : "ANSWER",
            answer   : answer
        })
        
    } catch (error) {
        // 
    }
}

export const handleWebRTCAnswer = async (data: any) => {
    try {
        await peerConnection.setRemoteDescription(new RTCSessionDescription(data.answer))
    } catch (error) {
        // 
    }
}

export const handleWebRTCCandidate = async (data: any) => {
    try {
        console.log("HANDLE WEB RTC CANDIDATE", data)
        await peerConnection.addIceCandidate(data.candidate)
    } catch (error) {
        console.log("Error trying to add ice candidate", error)
    }
}