import SeacrhItem from "@/components/molecules/search-items/SeacrhItem";
import { Avatar, Button, Form, Input, List, message, Modal, Space, Typography } from "antd"
import { MemberInterface } from "@/interfaces/user-interface";
import { DeleteOutlined } from "@ant-design/icons";
import HttpClient from "@/utils/HttpClient";
import { config } from "@/config";
import { useState } from "react";

import "./create-modal.less";


const CreateModal = (props: any) => {
    const [form] = Form.useForm();
    const [userList, setUserList] = useState([]);
    const [dataMembers, setDataMembers]:any[] = useState([]);
    const [userListLoading, setUserListLoading] = useState(false);

    const onSearchMember = (value: string) => {        
        setUserListLoading(true)
        HttpClient.get('/users', {
            params: {
                username: value 
            }
        })
        .then(_res=> {
                setUserListLoading(false)
                setUserList(_res.data)
            })
            .catch(_err => {
                message.error('Error create data!');
            })
    };
    
    const onClickMember = (member: MemberInterface) => {
        if (dataMembers.length) {
            if (dataMembers.some((item: MemberInterface) => member.username !== item.username)) {
                const data: any = [...dataMembers, member];
                setDataMembers(data);
            }
        } else {
            const data: any = [...dataMembers, member];
            setDataMembers(data);
        }
    }

    const handleDeleteMember = (member: MemberInterface) => {
        const data = dataMembers.filter((item: MemberInterface) => member.username !== item.username)
        setDataMembers(data);
    }

    /**
     * FORM
     */
    const onFinish = (data:any) => {
        const payload = {
            "lat": data?.lat ?? 0,
            "lng": data?.lng ?? 0,
            "name": data?.name ?? '',
            "address": data?.address ?? '',
            "members": dataMembers ?? []
        }

        HttpClient.post('/geolocations', payload)
            .then(_res=> {
                form.resetFields()
                message.success('Submit success!');
                props.onCancel();
            })
            .catch(_err => {
                message.error('Error create data!');
            })

    };

    const onFinishFailed = () => {
        message.error('Submit failed!');
    };

    return <Modal
        {...props}
        footer={null}
    >
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >

            <Form.Item
                name="name"
                label="Nama Lokasi"
                rules={[
                    {
                        required: true,
                        type: 'string'
                    }
                ]}
            >
                <Input placeholder="Masukkan Nama Lokasi" />
            </Form.Item>
            <Form.Item
                name="address"
                label="Alamat"
                rules={[
                    {
                        required: true,
                        type: 'string'
                    }
                ]}
            >
                <Input placeholder="Masukkan Alamat"/>
            </Form.Item>
            <Form.Item
                name="lat"
                label="Latitude"
                rules={[
                    {
                        required: true
                    }
                ]}
            >
                <Input placeholder="Masukkan Latitide"  type={'number'}/>
            </Form.Item>
            <Form.Item
                name="lng"
                label="Longitude"
                rules={[
                    {
                        required: true
                    }
                ]}
            >
                <Input placeholder="Masukkan Longitude" type={'number'}/>
            </Form.Item>
            <Typography.Title level={5} className="member-lebel" style={{color: '#d1d1d1'}}>Members</Typography.Title>
            <SeacrhItem 
                data={userList} 
                loading={userListLoading}
                onSearch={(value: string) => onSearchMember(value)}
                onClick={(item: any) => onClickMember(item)}
                placeholder="Search user by username"
            />
            {
                dataMembers.length &&
                    <div className="member-box">
                        <List
                            itemLayout="horizontal"
                            dataSource={dataMembers}
                            className="member-list"
                            renderItem={(item:any) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src={item?.avatar} />}
                                        title={<a target="_blank" rel="noopener noreferrer" href={`${config('callink_url')}/direct/${item.username}`}>{item.name}</a>}
                                        description={item?.emails[0]?.address ?? '-'}
                                    />
                                    <Button type="primary" ghost danger className="btn-call" onClick={() => handleDeleteMember(item)}><DeleteOutlined /> Delete</Button>
                                </List.Item>
                            )}
                        />
                    </div>
            }

            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                    <Button type="primary" danger htmlType="button" onClick={() => {
                        props.onCancel()
                        form.resetFields()
                    }}>
                        Cancel
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    </Modal>
}

export default CreateModal