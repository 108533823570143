import { useEffect } from "react";
import { useQuery } from "@/utils/Helper";
import { setCookie } from "typescript-cookie";
import HttpClient from "@/utils/HttpClient";

const Callback = () => {
    const query : any = useQuery()
    const code : string = query.get('code') as string
    
    useEffect(() => {
        HttpClient.post('/oauth/callback', {
           code: code
        })
        .then((resp) => {
            setCookie('token', resp?.data?.token)
            setCookie('refresh_token', resp?.data?.refresh_token)
            window.location.href = `/geolocation`
        })
    }, [code])

    return <></>
}

export default Callback;