import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import { removeCookie } from "typescript-cookie"
import HttpClient from "./HttpClient"

export const useQuery = () => {
    const location = useLocation()
    return useMemo(() => new URLSearchParams(location.search), [location])
}

export const logoutHandler = () => {
    removeCookie('token');
    removeCookie('refresh_token');
    window.location.href = `/`
}

export const getProfile = async () => {
    try {
        const { data } = await HttpClient.get(`/me`)
        return data;
    } catch (_er) {
        console.log(_er, "Error get profile");
    }
}