import React from "react";
import {
  BrowserRouter
} from "react-router-dom";
import BaseRoutes from './BaseRoutes';
import { Provider } from 'react-redux';
import { store } from '@/redux/store';

const AudioVideoCallHandler = React.lazy(() => import("./components/organisms/audio-video-call/AudioVideoCallHandler"));

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <AudioVideoCallHandler/>
          <BaseRoutes/>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
