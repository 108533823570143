import { GeolocationInterface, InfoModalInterface } from "@/interfaces/geolocation-interface";
import { setActive, setCalled, setLocalStream } from "@/redux/reducers/audioVideoCallSlice";
import { Avatar, Button, List, message, Modal, Row, Skeleton, Typography } from "antd"
import { Fragment, useEffect, useState } from "react";
import { PhoneOutlined } from "@ant-design/icons";
import { useAppDispatch } from "@/redux/hooks";
import HttpClient from "@/utils/HttpClient";

import './info-modal.less';
import { ControllStateInterface } from "@/interfaces/audio-video-call-interface";
import { getProfile } from "@/utils/Helper";
import socketInstance from "@/utils/SocketHandler";

const initialControlState: ControllStateInterface = {
    audio: true,
    video: true,
    screen: false
}

const { Title } = Typography;
const InfoModal = (props: InfoModalInterface) => {
    const { geoId, onOk, onCancel, visible } = props;
    const [detailGeolocation, setDetailGeolocation] = useState<GeolocationInterface|null>(null);
    const [loading, setLoading] = useState<any>({
        callBtn: false
    });
    const dispatch = useAppDispatch();

    useEffect(() => {
        HttpClient.get(`/geolocations/${geoId}`)
            .then(resp => {   
                setDetailGeolocation(resp.data)
            })
            .catch(_err => {
                message.error("Error to get detail geolocation");
            });
    }, [geoId]);

    const handleModalCall = async (_type: string, username: string) => {
        try {
            setLoading((state: any) => ({ ...state, callBtn: true }));
            const called = await HttpClient.get(`/users/${username}/socket`);

            dispatch(setCalled({
                ...called.data, 
                ...initialControlState,
                avatar: process.env.REACT_APP_CALLINK_URL+"/avatar/"+username
            }))

            navigator.mediaDevices.getUserMedia(initialControlState)
                .then(stream => {
                    setLoading((state:any) => ({ ...state, callBtn: false }));
                    dispatch(setLocalStream(stream));
                })
                
            const caller = await getProfile();
            sendToCalled(caller, called.data);
            
            dispatch(setActive(true));
            onCancel(false);
        } catch (error) {
            message.error("Failed to call");
        }
    }

    const sendToCalled = (caller: any, called: any) => {
        
        const data = {
            "calleeId": called.socketid, // callee
            "type": "VIDEO_CALL",
            "caller": {
                "socketId": socketInstance.id,
                "username": caller.username,
                "avatar": caller.avatar,
            },
            "callee": {
                "socketId": called.socketid,
                "username": called.username,
                "avatar": process.env.REACT_APP_CALLINK_URL+"/avatar/"+called.username
            }
        }
        socketInstance.emit("pre-offer", data);
    }    

    return <Fragment>
            <Modal
                onOk={() => onOk}
                onCancel={onCancel}
                visible={visible}
                footer={null}
                className="info-box"
            >
                <Skeleton active paragraph={{ rows: 4 }} loading={!!!detailGeolocation}>
                    <Title level={4} type="warning">{detailGeolocation?.name}</Title>
                    {/* <Button type="primary" size="large" className="video-conference-btn">Video Conference</Button> */}
                    <Row style={{marginTop: '12px'}}>
                        <Title level={5}>Members</Title>
                    </Row>
                    <Skeleton active loading={!!!detailGeolocation?.members}>
                        <List
                            itemLayout="horizontal"
                            dataSource={detailGeolocation?.members}
                            className="member-list"
                            renderItem={(item:any) => (
                                <List.Item>
                                    <List.Item.Meta
                                    avatar={<Avatar src={item?.avatar} />}
                                    title={<a href="https://ant.design">{item.name}</a>}
                                    description={item?.emails[0]?.address ?? '-'}
                                    />
                                    <Button type="ghost" className="btn-call" onClick={() => handleModalCall('audio', item.username)} loading={loading?.callBtn}><PhoneOutlined /> Call</Button>
                                </List.Item>
                            )}
                        />
                    </Skeleton>
                    {/* <Row justify="center">
                        <Button type="ghost">Load More...</Button>
                    </Row> */}
                </Skeleton>
            </Modal>
        </Fragment>
}

export default InfoModal