import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Fragment, useState } from "react";
import { Button, Typography } from 'antd';
import L from 'leaflet';

import "./geo-maps.less"
import InfoModal from "./InfoModal";
interface GeoMapsTypes {
    key?: number,
    lat: number,
    lng: number,
    name: string,
    _id?: string
}

const GeoMaps = (props: { data: GeoMapsTypes[] }) => {
    const { data } = props

    const [infoModal, setInfoModal] = useState<string|null>(null);

    var pinPointIcon = L.icon({
        iconUrl: 'icons/pint-point.png',
        iconSize: [25, 36],
        iconAnchor: [12, 36],
        popupAnchor: [0, -40],
        shadowSize: [68, 95],
        shadowAnchor: [33, 95],
        shadowUrl:'icons/pint-point-shadow.png'
    });

    const renderMarkers = (pinPoints: any) => {
        return pinPoints.map((item: any, key: number) => {
            return (
                <Marker position={[item.lat, item.lng]} icon={pinPointIcon} key={key}>
                    <Popup>
                        <Typography.Title level={5} type="warning">{item.name}</Typography.Title>
                        <Button className="detail-btn" type="ghost" onClick={() => setInfoModal(item._id)}>Lihat Detail</Button>
                    </Popup>
                </Marker>
            )
        })
    }
    
    return <Fragment>
        <MapContainer center={[-4.127, 127.090]} zoom={5} scrollWheelZoom={true}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {renderMarkers(data)}
        </MapContainer>
        {
            !!infoModal &&
                <InfoModal
                    visible={!!infoModal}
                    onCancel={() => setInfoModal(null)}
                    geoId={infoModal}
                />
        }
    </Fragment>
}


export default GeoMaps;