import { ControllStateInterface } from '@/interfaces/audio-video-call-interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AudioVideoCallState {
    active: boolean,
    localStream?: MediaStream | null,
    remoteStream?: MediaStream | null,
    mediaControll?: ControllStateInterface,
    called?: any,
    caller?: any
}

const initialState: AudioVideoCallState = {
    active: false,
    localStream: null,
    remoteStream: null,
    called: null,
    caller: null
}

export const audioVideoCallSlice = createSlice({
    name: 'audioVideoCall',
    initialState,
    reducers: {
        setActive: (state, active: PayloadAction<boolean>) => {
            state.active = active.payload
        },
        setLocalStream: (state, localStream: PayloadAction<MediaStream | null>) => {
            state.localStream = localStream.payload
        },
        setRemoteStream: (state, remoteStream: PayloadAction<MediaStream | null>) => {
            state.remoteStream = remoteStream.payload
        },
        setCalled: (state, called: PayloadAction<boolean>) => {
            state.called = called.payload
        },
        setCaller: (state, caller: PayloadAction<any>) => {
            state.caller = caller.payload
        }
    },
})

export const { 
    setActive, 
    setLocalStream, 
    setRemoteStream,
    setCalled,
    setCaller
} = audioVideoCallSlice.actions;

export default audioVideoCallSlice;
