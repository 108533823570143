import { GeolocationInterface } from "@/interfaces/geolocation-interface";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { Button, message, Space, Table } from "antd";
import { Fragment } from "react"

import "./geo-tables.less"
import confirm from "antd/lib/modal/confirm";
import HttpClient from "@/utils/HttpClient";
import { MemberInterface } from "@/interfaces/user-interface";

const GeoTables = (props: any) => {
    const { data, onChangePagination, onGeolocationSelected, reFetch } = props;
    const pagination = {
        total: data?.total,
        current: data?.page,
        pageSize: data?.per_page
    }

    const deleteConfirm = (id: string) => {
        confirm({
        title: 'Apapah anda ingin menghapus data ini ?',
        icon: <ExclamationCircleOutlined />,
        content: 'Ini akan menghapus data secara permanen',
        onOk() {
            
            HttpClient.delete('/geolocations/'+id)
                .then(_res => {
                    reFetch(_res);
                    message.success('Hapus data berhasil.');
                })
                .catch(_err => {
                    message.error('Gagal hapus data');
                })
        }
        });
    };

    const columns: ColumnsType<GeolocationInterface> = [
        {
            title: 'Lokasi',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Latitude',
            dataIndex: 'lat',
            key: 'lat',
        },
        {
            title: 'Longitude',
            dataIndex: 'lng',
            key: 'lng',
        },
        {
            title: 'Members',
            dataIndex: 'members',
            key: 'members',
            render: (members: any) => {   
                const limit_member = members?.slice(0, 3) ?? [];
                return limit_member.map((member: MemberInterface, key: any) => {
                    return (key ? ", ": "") + member.username;
                })
            }
        },
        {
            title: 'Action',
            key: 'action',
            width: "100px",
            render: (item) => {
                return <>
                    <Space size={10}>
                        <Button type="primary" ghost onClick={() => onGeolocationSelected(item)}>
                            <EditOutlined /> Sunting
                        </Button>
                        <Button type="primary" ghost onClick={()=>deleteConfirm(item._id)}><DeleteOutlined /> Hapus</Button>
                    </Space>
                </>
            }
        },
    ]    

    return <Fragment>
        <Table 
            columns={columns} 
            dataSource={data.data} 
            className={"geo-tables"}
            pagination={pagination}
            onChange={onChangePagination}
        />
    </Fragment>
}

export default GeoTables;