import axios from "axios"
import { config } from "@/config";
import { getCookie, removeCookie, setCookie } from "typescript-cookie";

const HttpClient = axios.create({
    baseURL: config('api_url')
});

HttpClient.interceptors.request.use(async (request: any) => {
    const token = getCookie('token');
    request.headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    }

    return request
}, (error: any) => {
    return Promise.reject(error);
})

HttpClient.interceptors.response.use(async (response: any) => {
    return response.data
}, async (error: any) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        await refreshToken();
        return HttpClient(originalRequest);
    } else {
        return Promise.reject(error);
    }
})

const refreshToken = async () => {
    try {
        const refresh_token: string = getCookie('refresh_token') ?? "";
        const { data } = await HttpClient.post(`${config('api_url')}/oauth/refresh`, {
            refresh_token: refresh_token
        })
        setCookie('token', data.token);
        setCookie('refresh_token', data.refresh_token);
        HttpClient.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
    } catch (_err) {
        removeCookie('token');
        removeCookie('refresh_token');
        window.location.href = '/login';
    }
}


export default HttpClient;