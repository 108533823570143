import _ from "lodash"

const config_data : any = {
    callink_client_id : process.env.REACT_APP_CALLINK_CLIENT_ID,
    callink_client_secret : process.env.REACT_APP_CALLINK_CLIENT_ID,
    callink_redirect_url : process.env.REACT_APP_CALLINK_REDIRECT_URL,
    callink_authorize_url : process.env.REACT_APP_CALLINK_AUTHORIZE_URL,
    callink_url : process.env.REACT_APP_CALLINK_URL,
    api_url : process.env.REACT_APP_API_URL,
    stun_host : process.env.REACT_APP_STUN_HOST,
    turn_host : process.env.REACT_APP_TURN_HOST,
    turn_username : process.env.REACT_APP_TURN_USERNAME,
    turn_credential : process.env.REACT_APP_TURN_CREDENTIAL,
    ws_url: process.env.REACT_APP_WS_URL
}

export const config : any = ((name: string) => {
    return _.get(config_data, name, null)
})