import Loading from "@/components/organisms/loading/Loading";
import { Button, Col, Image, Row, Typography } from "antd";
import { LoginOutlined } from "@ant-design/icons";
import { logoutHandler } from "@/utils/Helper";

import "./style.less"

const Me = (props: any) => {
    const { data } = props
    const { Text } = Typography;
    
    return (
        <div className="me-box">
            <Row>
                {
                    data
                        ? <>
                            <Col span={24}>
                                <div className="box-image">
                                    <Image
                                        width={250}
                                        src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                                    />
                                </div>
                                <div className="box-info">
                                    <p>
                                        <Text strong>Nama</Text> <br/>
                                        <Text>{data?.name}</Text>
                                    </p>
                                    <p>
                                        <Text strong>Email</Text> <br/>
                                        <Text>{data?.emails.map((item:any, key:number) => item.address+(key>1? ', ': ''))}</Text>
                                    </p>
                                </div>
                            </Col>
                            <div className="info-logout">
                                <Button type="primary" size="large" ghost onClick={logoutHandler}><LoginOutlined /> Logout</Button>
                            </div>
                        </>
                        : <Loading/>
                }
            </Row>
        </div>
    ) 
}

export default Me;