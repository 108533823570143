import { Fragment} from "react"
import { getProfile } from "@/utils/Helper";
import Header from "./organisms/header/Header"
import socketInstance from "@/utils/SocketHandler";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setActive, setCalled, setCaller, setRemoteStream } from "@/redux/reducers/audioVideoCallSlice";
import { createPeerConnection, handleWebRTCAnswer, handleWebRTCCandidate, handleWebRTCOffer, sendWebRTCOffer } from "@/utils/WebRTCHanler";

const BaseLayout = (props: {children: any}) => {
    const dispatch = useAppDispatch();
    const getCalled = useAppSelector(state => state.audioVideoCall.called);
    const getLocalStream = useAppSelector(state => state.audioVideoCall.localStream);

    socketInstance.on("connect", async () => {
        const { _id, username } = await getProfile()
        const data = {
            username : username,
            socketId : socketInstance.id,
            userId   : _id,
            avatar: process.env.REACT_APP_CALLINK_URL+"/avatar/"+username,
            audio: true,
            video: true
        }
        socketInstance.emit('connected-users', data)
        dispatch(setCaller(data))
    });
    
    socketInstance.on('pre-offer-answer', (data) => {
        const {type} = data
        switch (type) {
            case "CALL_REJECTED":
                dispatch(setActive(false));
                window.location.href = '/geolocation';
                break;
            case "CALL_ACCEPTED":
                const pc = createPeerConnection({...data, socketId: data.callerId});

                // Receiving tracks
                const remoteStream = new MediaStream()
                dispatch(setRemoteStream(remoteStream))

                pc.ontrack = (event) => {
                    remoteStream.addTrack(event.track)
                }

                if (getLocalStream) {
                    for (const track of getLocalStream.getTracks()) {
                        pc.addTrack(track, getLocalStream)
                    }
                }
                
                if (getCalled) {
                    sendWebRTCOffer({socketId : getCalled.socketid})
                }
                break;
        
            default:
                console.log("Unknown type");
                break;
        }
    })

    socketInstance.on("webRTC-signaling", (data) => {
        if (getCalled) {
            switch (data.type) {
                case "OFFER":
                    handleWebRTCOffer({...data, socketId : getCalled.socketid})
                    break;
                case "ANSWER":
                    handleWebRTCAnswer({...data, socketId : getCalled.socketid})
                    break;
                case "ICE_CANDIDATE":
                    handleWebRTCCandidate({...data, socketId : getCalled.socketid})
                    break;
            
                default:
                    console.log("Unknown type");
                    break;
            }
        }
    })

    socketInstance.on('action-user-call', (data) => {
        if (getCalled) {
            switch (data.type) {
                case "MIC":
                    dispatch(setCalled({...getCalled, audio: !data.disabled}))
                    break;
                case "CAMERA":
                    dispatch(setCalled({...getCalled, video: !data.disabled}))
                    break;
                case "HANGUP":
                    window.location.href = '/geolocation';
                    break;
            
                default:
                    break;
            }
        }
        
        
    })

    return (
        <Fragment>
            <Header/>
            {props.children}
        </Fragment>
    )
}

export default BaseLayout;