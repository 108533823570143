import { configureStore } from '@reduxjs/toolkit';
import audioVideoCallSlice from './reducers/audioVideoCallSlice';

export const store = configureStore({
    reducer: {
        audioVideoCall: audioVideoCallSlice.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch