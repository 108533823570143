export const GeolocationMapper = (geolocations: any): any => {
    
    const data: any = geolocations.data.map((geolocation: any, key: number) => {
        geolocation.key = key;
        return geolocation
    })
    
    return {
        ...geolocations,
        data: data
    }
}